import React, { useContext, useEffect, useMemo } from 'react';

import t from '@jetshop/intl';
import { useLocation } from 'react-router';
import { trackPageEvent } from '@jetshop/core/analytics/tracking';
import { useTracker } from '@jetshop/core/analytics/Analytics';

import { Link } from 'react-router-dom';
import { styled } from 'linaria/react';
import MaxWidth from '../Layout/MaxWidth';
import DynamicContent from '../DynamicContent';
import ContactForm from '../ContactForm/ContactForm';
import TitleWithLines from '../ui/TitleWithLines';
import { Button } from '../ui/Button';
import ModalTrigger from '../ui/ModalTrigger';
import { ReactComponent as CrossSvg } from '../../svg/Cross.svg';
import { Helmet } from 'react-helmet-async';

import { theme } from '../Theme';
import { StoreContext } from '../StoreHandler/StoreContext';

const Breadcrumbs = styled('span')`
  position: absolute;
  color: ${theme.colors.notWhite};
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  line-height: 28px;
  font-size: 10px;
  z-index: 9;
  margin-top: 0.5rem;

  a,
  a:hover,
  a:focus,
  a:active {
    color: inherit;
    text-decoration: none;
  }

  ${theme.above.md} {
    font-size: 12px;
    margin-top: 1.5rem;
    letter-spacing: 0.6px;
  }
`;

const InfoContainer = styled(MaxWidth)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 0.5rem;

  ${theme.above.md} {
    div + div {
      margin-left: 1.5rem;
    }
  }
`;

const InfoCard = styled('div')`
  background: ${theme.colors.beige};
  font-size: ${theme.fontSizes[0]};
  border-radius: 4px;
  flex: 0 0 100%;
  align-self: stretch;
  margin-top: 2rem;
  padding: 1.5rem;

  a {
    color: #911827;
  }

  p {
    line-height: 25px;

    & strong {
      font-weight: 700;
    }
  }

  p:empty {
    margin-bottom: 1.5rem;
  }

  table {
    width: 100%;
  }

  table tr {
    line-height: 25px;
  }

  ${theme.above.md} {
    flex: 0 0 calc(33% - 1.5rem);
    font-size: ${theme.fontSizes[2]};

    p:empty {
      margin-bottom: 2rem;
    }
  }
`;

const ModalContainer = styled(MaxWidth)`
  background: ${theme.colors.white};
  max-width: 100%;
  padding: 1rem 2rem 2rem !important;
  border-radius: 4px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.1);

  & div:nth-child(5) {
    text-align: center;
  }

  ${theme.below.md} {
    margin: 0;
    width: 100%;
    height: auto;
  }
`;

const CloseIcon = styled('button')`
  outline: none;
  background: #ffffff;
  position: absolute;
  right: 2rem;
  top: 2rem;

  svg {
    height: 14px;
    width: 14px;
    stroke-width: 0.5px;

    path {
      stroke: #111111;
    }
  }
`;

const decodeHtml = (html) => {
  if (typeof document === 'undefined') return html;

  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

const ContactFormModal = ({ hideTarget, store }) => (
  <ModalContainer>
    <CloseIcon onClick={hideTarget}>
      <CrossSvg />
    </CloseIcon>
    <ContactForm store={store} hideTarget={hideTarget} isModal />
  </ModalContainer>
);

const convertNewlinesToBr = (text) => {
  return text.replace(/\n/g, '<br/>');
};

const StoreDetail = (props) => {
  const { store, storeId } = props;
  const { storeList } = useContext(StoreContext);
  const track = useTracker();
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    track(trackPageEvent({ pathname: `${pathname}` }));
  }, [track, pathname]);

  const currentStore = useMemo(() => {
    return storeList.find(
      (store) =>
        store?.extra?.id === parseInt(storeId) && store?.extra !== undefined
    );
  }, [storeList]);

  return (
    <>
      <Helmet>
        <title>{`Butiken i ${store.name}`}</title>
        <meta
          name="description"
          content={`Möbelmästarna ${store.name} en del av Möbelmästarna, erbjuder ett utvalt sortiment av möbler. Hos oss kan du handla sängar och soffor av hög kvalitet`}
        />
      </Helmet>
      <MaxWidth>
        <Breadcrumbs>
          <Link to="/">{t('Home')}</Link> /{' '}
          <Link to="/butiker">{t('Stores')}</Link> / {store.name}
        </Breadcrumbs>
      </MaxWidth>

      <DynamicContent content={decodeHtml(store.description)} />
      {/* <DynamicContent content={store.description} /> */}

      <>
        <MaxWidth>
          <TitleWithLines style={{ width: '100%', marginTop: '1rem' }}>
            {t('Welcome!')}
          </TitleWithLines>
        </MaxWidth>
        <InfoContainer>
          {currentStore && currentStore?.storeInfo ? (
            <>
              <InfoCard
                dangerouslySetInnerHTML={{
                  __html: convertNewlinesToBr(
                    '<h2>Öppettider</h2>' +
                      currentStore?.storeInfo?.['open-hours']
                  ),
                }}
              />
              <InfoCard dangerouslySetInnerHTML={{ __html: store.address2 }} />

              <InfoCard>
                <h2>Kontakt</h2>
                <p>{currentStore?.storeInfo.address}</p>
                <p>
                  {currentStore?.storeInfo.zip} {currentStore?.storeInfo.city}
                </p>
                <p>{currentStore?.storeInfo.phone}</p>
                <p>
                  <a href={`mailto:${currentStore?.storeInfo.email}`}>
                    {currentStore?.storeInfo.email}
                  </a>
                </p>
                <ModalTrigger
                  target={(props) => (
                    <ContactFormModal store={store} {...props} />
                  )}
                >
                  {({ showTarget }) => (
                    <Button style={{ marginTop: '2rem' }} onClick={showTarget}>
                      {t('Kontaktformulär')}
                    </Button>
                  )}
                </ModalTrigger>
              </InfoCard>
            </>
          ) : (
            <>
              <InfoCard dangerouslySetInnerHTML={{ __html: store.openHours }} />
              <InfoCard>
                <div dangerouslySetInnerHTML={{ __html: store.contact }} />

                <ModalTrigger
                  target={(props) => (
                    <ContactFormModal store={store} {...props} />
                  )}
                >
                  {({ showTarget }) => (
                    <Button style={{ marginTop: '2rem' }} onClick={showTarget}>
                      {t('Kontaktformulär')}
                    </Button>
                  )}
                </ModalTrigger>
              </InfoCard>
              <InfoCard dangerouslySetInnerHTML={{ __html: store.address2 }} />
            </>
          )}
        </InfoContainer>
      </>

      {store.other && <DynamicContent content={store.other} />}
    </>
  );
};

export default StoreDetail;
