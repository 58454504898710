import { FlyoutTarget, FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import SuggestedTerm from '@jetshop/ui/Search/SuggestedTerm';
import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import SearchAutoCompleteContainer from '@jetshop/ui/Search/SearchAutoCompleteContainer';
import t from '@jetshop/intl';
import { ReactComponent as SearchSvg } from '../../../svg/Search.svg';
import AutocompleteQuery from './AutocompleteQuery.gql';
import { StoreContext } from '../../StoreHandler/StoreContext';
const Wrapper = styled('div')`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
`;

const SearchFieldWrapper = styled('div')`
  width: 12rem;
  position: relative;
  font-size: 16px;
  font-weight: normal;
`;

const Flyout = styled('div')`
  position: absolute;
  background: white;
  z-index: 999;
  border: 1px solid #e8e8e8;
  width: 100%;
  top: 2.4rem;
  left: 0;
`;

const CancelButton = styled('button')`
  font-size: 0.875rem;
  border: 0;
  background: transparent;
  color: #878787;
  margin-left: 1rem;
`;

const SearchButton = styled('button')`
  position: absolute;
  right: 4px;
  top: 4px;
  height: 32px;
  width: 32px;
  padding: 8px;
  z-index: 9;
  border: none;

  background: transparent;

  svg path {
    stroke: #000;
  }
`;

const resultStyles = css`
  ul {
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;

    li {
      line-height: 16px;
    }
  }
  h2 {
    margin: 0;
  }
  ul + h2 {
    margin-top: 1em;
  }
`;

const SearchResults = ({ term, result, loading, onClick }) => {
  const { selectedStore } = useContext(StoreContext);

  const products = result?.products?.result;
  const categories = result?.categories;

  if (
    !result ||
    (!loading && products?.length === 0 && categories?.result?.length === 0)
  ) {
    return null;
  }

  const cleanProducts = products?.filter((prod) => {
    const store = prod?.customFields?.find((f) => f.key === 'store');
    if (typeof store === 'undefined') {
      return prod;
    }
    if (store?.stringValue === selectedStore?.name) {
      return prod;
    }
  });

  return (
    <div className={resultStyles}>
      {cleanProducts?.length > 0 && (
        <>
          <h2>{t('Products')}</h2>
          <ul style={loading ? { opacity: 0.5 } : null}>
            {cleanProducts?.map((item) => (
              <SuggestedTerm
                term={term}
                onClick={onClick}
                key={item?.id}
                item={item}
              />
            ))}
          </ul>
        </>
      )}

      {categories?.result?.length > 0 && (
        <>
          <h2>{t('Categories')}</h2>
          <ul style={loading ? { opacity: 0.5 } : null}>
            {categories?.result?.map((item) => (
              <SuggestedTerm
                term={term}
                onClick={onClick}
                key={item?.id}
                item={item}
              />
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

const SearchField = ({
  initialValue,
  className,
  placeholder = '',
  ...rest
}) => {
  return (
    <SearchAutoCompleteContainer
      initialValue={initialValue}
      autocompleteQuery={AutocompleteQuery}
      {...rest}
    >
      {(searchField) => (
        <Wrapper className={className}>
          <SearchFieldWrapper>
            <FlyoutTrigger showCover={false} id="searchFlyout">
              {({ showTarget, hideTarget }) => (
                <React.Fragment>
                  <input
                    id="hr-search"
                    placeholder={placeholder}
                    {...searchField?.getInputProps({
                      onFocus: showTarget,
                    })}
                  />

                  <SearchButton onClick={() => searchField?.triggerSearch()}>
                    <SearchSvg />
                  </SearchButton>

                  <CancelButton
                    {...searchField?.getCancelProps({ onClick: hideTarget })}
                  >
                    {t('Cancel')}
                  </CancelButton>
                </React.Fragment>
              )}
            </FlyoutTrigger>

            <FlyoutTarget id="searchFlyout">
              {(flyout) =>
                searchField?.isDirty &&
                !(searchField?.loading && searchField?.result?.length === 0) &&
                searchField?.isOpen &&
                flyout?.isOpen && (
                  <Flyout>
                    <div {...searchField?.getFlyoutProps()}>
                      {searchField?.isDirty && (
                        <React.Fragment>
                          <SearchResults
                            term={searchField?.term}
                            result={searchField?.result}
                            onClick={flyout?.hideTarget}
                            loading={searchField?.loading}
                          />
                        </React.Fragment>
                      )}
                    </div>
                  </Flyout>
                )
              }
            </FlyoutTarget>
          </SearchFieldWrapper>
        </Wrapper>
      )}
    </SearchAutoCompleteContainer>
  );
};

SearchField.Flyout = Flyout;
SearchField.Wrapper = SearchFieldWrapper;
SearchField.Cancel = CancelButton;

export default SearchField;
