import { Hero } from './Hero';
import { BoxRow } from './BoxRow';
import { CampaignRow, CampaignRowProduct } from './CampaignRow';
import { ImageBoxItem, TextBoxItem, ProductBoxItem } from './BoxItems';
import { Spacer } from './Spacer';
import { ProductRow, ProductRowItem } from './ProductRow';
import { TextPush } from './TextPush';
import { Title } from './Title';
import { ContentRow, ContentRowItem } from './ContentRow';
import { VideoPopup } from '../Content/PreviewAndVideoPopup';
import { CareProduct } from './CareProduct';

export const contentEditorComponents = {
  HERO: Hero,
  TEXTBOX: TextBoxItem,
  IMAGEBOX: ImageBoxItem,
  PRODUCTBOX: ProductBoxItem,
  SPACER: Spacer,
  BOXROW: BoxRow,
  CAMPAIGNROW: CampaignRow,
  CAMPAIGNROWPRODUCT: CampaignRowProduct,
  PRODUCTROW: ProductRow,
  PRODUCTROWITEM: ProductRowItem,
  TEXTPUSH: TextPush,
  TITLE: Title,
  CONTENTROW: ContentRow,
  CONTENTROWITEM: ContentRowItem,
  VIDEOPLAYER: VideoPopup,
  CAREPRODUCT: CareProduct,
};
