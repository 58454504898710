import React from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../Layout/MaxWidth';
import Slider from 'react-slick';
import '../Content/slickCSS.css';
import { ReactComponent as Arrow } from '../../svg/Arrow.svg';
import { ProductCard } from '../CategoryPage/ProductCard';
import { theme } from '../Theme';

const Container = styled('div')`
  h1 {
    font-family: ${theme.fonts.primary};
    font-size: 28px;
    line-height: 34px;
    font-weight: 500;
    text-align: center;
    margin: 16px 0;
  }
  h2 {
    font-family: ${theme.fonts.primary};
    font-size: 60px;
    line-height: 60px;
    font-weight: 700;
    text-align: center;
    margin: 0;
    margin-bottom: 20px;
  }
  h3 {
    font-family: ${theme.fonts.primary};
    font-size: 24px;
    line-height: 29px;
    font-weight: 700;
    text-align: center;
    margin: 0;
  }
  [data-testid='product'] {
    width: 100%;
  }

  .slick-track {
    padding-left: 2px;
  }

  [aria-hidden='false'] {
    margin-left: -1px;
  }

  margin-bottom: 2rem;

  .mastarpris {
    .productcard {
      .productcard {
        display: none;
      }
    }
  }
`;

const ArrowWrapper = styled('div')`
  height: 30px;
  width: 30px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  position: absolute;
  top: 35%;
  transform: translateY(-50%);
  z-index: 1;
  background: white;

  &.next {
    transform: rotate(270deg);
    right: -15px;
    left: auto;
    ${theme.below.md} {
      right: 0;
    }
  }

  &.prev {
    transform: rotate(90deg);
    right: auto;
    left: -15px;
    ${theme.below.md} {
      left: 0;
    }
  }
`;

const CustomArrow = styled(Arrow)`
  height: 14px;
  width: 14px;
`;

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <ArrowWrapper className={className + ' prev'} onClick={onClick}>
      <CustomArrow />
    </ArrowWrapper>
  );
}

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <ArrowWrapper className={className + ' next'} onClick={onClick}>
      <CustomArrow />
    </ArrowWrapper>
  );
}

const SliderWithButtons = ({ children }) => {
  const deskstopSlides = children?.length < 5 ? children?.length : 4;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: deskstopSlides,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    draggable: true,
    responsive: [
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return <Slider {...settings}>{children}</Slider>;
};

export const ProductRowItem = ({ product }) => {
  return <ProductCard product={product?.value} />;
};

export const ProductRow = ({ children, title }) => {
  return (
    <MaxWidth>
      <Container>
        {title?.value && <h1>{title?.value}</h1>}

        <SliderWithButtons>{children}</SliderWithButtons>
      </Container>
    </MaxWidth>
  );
};
